.placeholder {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  font-size: 10vw;
  color: gray;
}

.thumb-img {
  border-radius: 10px;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
}
