.videoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 100%;
  border-radius: 15px;
}

.videoBox img {
  max-width: 100%;
  height: 40rem;
  object-fit: contain;
}

.video {
  width: 100%;
  height: 40rem;
  border-radius: 15px;
  margin-bottom: 1%;
}

.videoActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.previewDisclaimer p {
  color: darkred;
  justify-content: center;
  margin-top: 15px;
}
