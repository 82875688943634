.layout {
  height: 100%;
  padding: 1%;
}

.layout-content {
  padding-top: 2%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 60px;
}

.layout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.layout-header > * {
  margin-left: 2%;
  margin-right: 8%;
}

.grid-item {
  overflow: auto;
  padding-top: 1%;
  padding-bottom: 3%;
  padding-left: 2%;
  padding-right: 2%;
  min-width: 33.33%;
  max-width: 33.33%;
  min-height: 50%;
  max-height: 50%;
  border: 1px solid #d5d5d5;
  background-color: #f5f5f5;
  border-radius: 25px;
}

.grid-item-content {
  height: 100%;
}

.grid-item-body {
  flex-grow: 1;
  height: 100%;
}

.grid-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-size: smaller;
}

.title {
  font-size: 2em;
  font-weight: bold;
}
