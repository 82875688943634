.ant-layout {
  background-color: transparent;
  max-height: 100vh;
}

.site-layout {
  background-color: #f4f4f4;
}

.ant-layout-sider {
  border-right: 1px solid #f0f0f0;
}

.ant-layout-header {
  background-color: transparent;
}

.ant-layout-footer {
  text-align: center;
  background-color: #f4f4f4 !important;
}

.ant-layout-content {
  background-color: transparent;
}

.dashboard-footer {
  background-color: #f4f4f4;
}

.dashboard-content {
  background-color: #f4f4f4;
  margin: 0 16px;
}
