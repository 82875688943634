.home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding-left: 2%;
}

.home-header {
  width: 100%;
  padding-top: 3%;
  text-align: left;
  font-weight: 600;
  font-size: 28px;
}

.welcome-card {
  background-image: url("../../../images/dashboard_get_app_banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  /*height: 360px; !* set the height of the div *!*/
  padding: 2%;
  border-radius: 10px;
  display: flex;
  width: 95%;
}

.welcome-list {
  /*line-height: 35px;*/
  color: white;
  width: 60%;
  padding: 30px;
}

.welcome-list > * {
  text-align: left;
  margin-right: 50px;
}

.welcome-list-header {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 10px;
  color: white;
}

.carousel-wrapper {
  width: 95%;
}

.carousel-item {
  padding: 2%;
  aspect-ratio: 16 / 9;
  /* text-align: left; */
}

.ant-carousel .slick-next::before {
  content: "";
}

.ant-carousel .slick-prev::before {
  content: "";
}

.ant-carousel .slick-list .slick-slide>div>div {
  vertical-align: bottom;
  text-align: -webkit-center;
}

a.ant-btn {
  line-height: 45px;
}

.project-heading-btn {
  align-self: start;
  margin-top: 30px;
  width: 50%;
  height: 45px;
  line-height: 45px;
}

.ant-btn-link {
  border-radius: 25px;
  border-color: #ffffff;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.ant-btn-link:hover {
  border-color: white;
  color: white;
  background-color: #323953;
  background: #323953;
}

.ant-btn-link:focus {
  border-color: #4f4f4f;
  color: #4f4f4f;
  background-color: white;
}