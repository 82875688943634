.Auth {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url("../../images/dashboard_bg_image.jpg") no-repeat center center;
  background-size: cover;
}

.isoAuthContent {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  max-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  align-items: center;

  @media only screen and (max-width: 767px) {
    max-width: 300px;
  }
}

.isoAuthForm {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.isoInputWrapper {
  margin-bottom: 15px;
}

.ant-input:focus {
  border-color: #e9e9e9;
  box-shadow: 0 0 0 0px #fff;
}

.aquiferCheck {
  display: inline-grid;
}
/* // .linkStyle {
//   font-size: 12px;
//   color: ${palette('text', 2)};
//   margin-bottom: 10px;

//   &:hover {
//     color: ${palette('text', 2)};
//     text-decoration: underline;
//   }
// } */

.logo {
  width: 40%;
  padding-top: 24px;
}

.align-left {
  align-self: flex-start;
}

.align-center {
  align-self: center;
}

button {
  font-weight: 500;
  width: 100%;
  height: 42px;
  border: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}
.ant-checkbox .ant-checkbox-inner {
  border-color: black;
}

.ant-checkbox-wrapper {
  margin: 0;
  padding: 0;
  color: black;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: flex;
  line-height: unset;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
