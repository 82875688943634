.create-project {
  display: block;
}

.create-project-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
}

.create-project-footer {
  padding-top: 2%;
}

.footage-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footage-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 4%;
}

.footer-actions {
  display: flex;
  flex-direction: row;
}

.checked {
  margin: 0.5%;
  width: 24%;
  border-radius: 15px;
  border: 1px solid gray;
}

.unchecked {
  margin: 0.5%;
  width: 24%;
  border-radius: 15px;
  border: 1px solid white;
}
