.anticon {
  color: black;
}

.sidenav {
  padding-top: 5%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidenav ul {
  padding-top: 30%;
}

.project-btn {
  width: 75%;
  height: 45px;
  font-weight: bold;
}
